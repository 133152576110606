//COLORS

$color-primary: #F6BF21;
$color-primary-light: #f7f451;
$color-primary-dark: #9c7115;

$color-secondary: #B5D7EA;
$color-secondary-light: #d3e5f0;
$color-secondary-dark: #2778a7;

$color-tertiary: #851212;
$color-tertiary-light: #cf4444;
$color-tertiary-dark: #611111;

$color-gray-dark: #7F7F7F;
$color-gray-dark-3:#333;
$color-white: #fff;
$color-black: #000;
$color-gray-dark-2:#999;
$color-gray-medium: #eee;
$color-gray-light-1: #f7f7f7;


// FONT
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$grid-width-small: 60rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;