.form {

    &__group:not(:last-child) {
        margin-bottom: 2rem;
    }

    &__input {
        font-size: 1.5rem;
        font-family: inherit;
        color: inherit;
        padding: 1.5rem 2rem;
        border-radius: .2rem;
        background-color: rgba($color-white, .5);
        border: none;
        border-bottom: .3rem solid transparent;
        width: 90%;
        display: block;
        transition: all .7s;

        @include respond(tab-port) {
            width: 100%;
        }

        &::-webkit-input-placeholder {
            color: $color-gray-dark-2;
        }

        &:focus {
            outline: none;
            box-shadow: 0 1rem 2rem rgba($color-black, .1);
            border-bottom: .3rem solid $color-primary;

            &:invalid {
                border-bottom: .3rem solid $color-secondary-dark;
            }
        }
    }

    &__label {
        font-size: 1.2rem;
        font-weight: 700;
        margin-left: 2rem;
        margin-top: .7rem;
        display: block;
        transition: all .3s;
    }

    &__input:placeholder-shown + &__label {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
    }

    &__radio-input {
        display: none;
    }

    &__radio-group {
        width: 49%;
        display: inline-block;

        @include respond(tab-port) {
            width: 100%;
            margin-bottom: 2rem;
        }
    }

    &__radio-label {
        font-size: $default-font-size;
        cursor: pointer;
        position: relative;
        padding-left: 4rem;
    }

    &__radio-button {
        height: 3rem;
        width: 3rem;
        border: .5rem solid $color-primary;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: -0.4rem;
        left: 0;

        &::after {
            content: "";
            background-color: $color-primary;
            display: block;
            height: 1.3rem;
            width: 1.3rem;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity:0;
            transition: opacity .1s;
        }
    }

    //sibling adjacent selector
    &__radio-input:checked ~ &__radio-label &__radio-button::after {
        opacity: 1;
    }
}