.row {
    max-width: $grid-width; //max-width instead of hard width for responsiveness
    margin: 0 auto; //center element

    &:not(:last-child) {
        margin-bottom: $gutter-vertical;

        @include respond(tab-port) {
            margin-bottom: $gutter-vertical-small;
        }
    }

    @include respond(tab-port) {
        max-width: $grid-width-small;
        padding: 0 3rem;
    }

    @include clearfix;

    // attribute selector selecting all class starts with col- elements
    [class^="col-"] {
        float: left;

        &:not(:last-child) {
            margin-right: $gutter-horizontal;

            @include respond(tab-port) {
                margin-right: 0;
                margin-bottom: $gutter-vertical-small;
            }
        }

        @include respond(tab-port) {
            width: 100% !important;
        }
    }

    .col-1-of-2 {
        width: calc((100% - #{$gutter-horizontal}) / 2);
    }

    .col-1-of-3 {
        width: calc((100% - #{$gutter-horizontal}*2) / 3);
    }

    .col-1-of-4 {
        width: calc((100% - #{$gutter-horizontal}*3) / 4);
    }

    .col-2-of-3 {
        width: calc(((100% - 2 * #{$gutter-horizontal}) / 3) * 2 + #{$gutter-horizontal});
    }

    .col-2-of-4 {
        width: calc(((100% - 3 * #{$gutter-horizontal}) / 4) * 2 + #{$gutter-horizontal});
    }

    .col-3-of-4 {
        width: calc(((100% - 3 * #{$gutter-horizontal}) / 4) * 3 + 2 * #{$gutter-horizontal});
    }
}