/* Universal reset to remove browser-specific styles */
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    /* Global font size so 1rem = 10px */
    /* but base on default font size in case of user settings */
    font-size: 62.5%;

    @include respond(big-desktop) {
        font-size: 75%; // 1rem = 12 12/16
    }
    @include respond(tab-land) {
        font-size: 56.25%; // 1rem = 9px 9/16
    }
    @include respond(tab-port) {
        font-size: 50%; // 1rem = 8px 8/16
    }
    @include respond(phone) { // Same as tab-port so not strictly necessary
        font-size: 50%;
    }
}

/* define sizing to include border and padding as well as content */
body {
    box-sizing: border-box;
    padding: 3rem;

    @include respond(tab-port) {
        padding: 0;
    }
}

::selection {
    background-color: $color-primary;
    color: $color-white;
}