.btn {

    &,
    &:link,
    &:visited {
        text-transform: uppercase;
        text-decoration: none;
        padding: 1.5rem 4rem;
        display: inline-block;
        border-radius: 10rem;
        transition: all .2s;
        position: relative;

        border: none;
        cursor: pointer;
    }

    &[disabled] {
        background-color: $color-gray-dark !important;
        opacity: .8;
        // make it unclickable
        pointer-events: none;
        cursor: default;
    }

    &:hover {
        transform: translateY(-0.3rem);
        box-shadow: 0 1rem 2rem rgba($color-black, .2);

        &::after {
            transform: scaleX(1.3) scaleY(1.6);
            opacity: 0;
        }
    }

    &:active,
    &:focus {
        transform: translateY(-0.1rem);
        box-shadow: 0 .5rem .7rem rgba($color-black, .2);
    }

    &::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 10rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .4s;
    }

    &--white {
        background-color: $color-tertiary;
        color: $color-white;

        &::after {
            background-color: $color-tertiary;
        }

        &[disabled] {
            &::after {
                background-color: $color-gray-dark !important;
            }
        }
    }

    &--green {
        background-color: $color-primary;
        color: $color-white;

        &::after {
            background-color: $color-white;
        }

        &[disabled] {
            &::after {
                background-color: $color-gray-dark !important;
            }
        }
    }

    &--animated {
        animation: moveInBottom .5s ease-out .75s;
        animation-fill-mode: backwards;
    }
}

.btn-text {

    &:link,
    &:visited {
        font-size: $default-font-size;
        color: $color-primary;
        display: inline-block;
        text-decoration: none;
        border-bottom: 1px solid $color-primary;
        padding: 3px;
        transition: all .2s;
    }

    &[disabled] {
        color: $color-gray-dark;
        border-bottom: 1px solid $color-gray-dark;
        opacity: .8;
        // make it unclickable
        pointer-events: none;
        cursor: default;
    }

    &:hover {
        &:not([disabled]) {
            background-color: $color-primary;
            color: $color-white;
            box-shadow: 0 1rem 2rem rgba($color-black, .15);
            transform: translateY(-2px);
        }
    }

    &:active {
        &:not([disabled]) {
            box-shadow: 0 .5rem 1rem rgba($color-black, .15);
            transform: translateY(0);
        }
    }

    &--sink {
        margin-top:1rem;
    }
}