.card {
    perspective: 150rem;
    -moz-perspective: 150rem;
    position: relative;
    height: 52rem;

    &__side {
        font-size: 2rem;
        position: absolute;
        top: 0;
        left: 0;
        height: 54rem;
        width: 100%;
        transition: all .8s ease;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: .3rem;
        overflow: hidden;
        box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

        &--front {
            background-color: $color-white;
        }

        &--back {
            transform: rotateY(180deg);

            &-1 {
                background-image: linear-gradient(to right bottom, $color-gray-medium, $color-white);
            }

            &-2 {
                background-image: linear-gradient(to right bottom, $color-gray-medium, $color-white);
            }

            &-3 {
                background-image: linear-gradient(to right bottom, $color-gray-medium, $color-white);
            }
        }
    }

    &:hover &__side--front {
        transform: rotateY(-180deg);
    }

    &:hover &__side--back {
        transform: rotateY(0);
    }

    &__picture {
        background-size: cover;
        background-blend-mode: screen;
        height: 100%;
        border-top-left-radius: .3rem;
        border-top-right-radius: .3rem;

        &--1 {
            background-image: url(../../../public/assets/images/sdw-project-log.jpg);
        }

        &--2 {
            background-image: url(../../../public/assets/images/sdw-project-silence.png);
        }

        &--3 {
            background-image: url(../../../public/assets/images/sdw-project-7con.jpg);
        }
    }

    &__heading {
        font-size: 1.8rem;
        font-weight: 300;
        text-transform: uppercase;
        text-align: right;
        color: $color-white;
        position: absolute;
        top: 3rem;
    }

    &__heading-span {
        padding: 1rem 1.5rem;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;

        &--1 {
            background-image: linear-gradient(to right bottom,
                    rgba($color-tertiary-dark, .85),
                    rgba($color-tertiary-light, .85));
        }

        &--2 {
            background-image: linear-gradient(to right bottom,
                    rgba($color-tertiary-dark, .85),
                    rgba($color-tertiary-light, .85));
        }

        &--3 {
            background-image: linear-gradient(to right bottom,
                    rgba($color-tertiary-dark, .85),
                    rgba($color-tertiary-light, .85));
        }
    }

    &__details {
        font-size: $default-font-size;
        padding: 8rem 2rem;

        p {
            color: $color-gray-dark-3;
        }
    }

    &__cta {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        text-align: center;
    }

    @include respond(tab-land-hover) {
        height: auto;
        border-radius: .3rem;
        background-color: $color-white;
        overflow: hidden;
        box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

        &__side {
            position: relative;
            height: 54rem;
            box-shadow: none;

            &--back {
                height: 41rem;
                transform: rotateY(0);
            }
        }

        &__heading-span {
            display: none;
        }

        &:hover &__side--front {
            transform: rotateY(0);
        }

        &__details {
            padding: 1rem 3rem;
        }
    }

    @include respond(tab-port-hover) {
        
        &__side {
            position: relative;
            height: 85rem;
            box-shadow: none;

            &--back {
                height: 41rem;
                transform: rotateY(0);
            }
        }
    }

    @include respond(phone) {
        &__side {
            height: 55vh;

            &--back {
                height: 40rem;
            }
        }
    }

    @media only screen and (max-height: 43.75em) and (hover:none) {
        &__side {
            height: 66vh;
        }
    }
}