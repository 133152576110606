.u-center-text {
    text-align: center !important;
}

.u-text-pop {
    color: $color-gray-dark-3;
    text-shadow: .5rem .5rem 2rem rgba($color-tertiary, .9);
}

.u-margin-bottom-large {
    margin-bottom: 8rem !important;

    @include respond(tab-port) {
        margin-bottom: 5rem !important;
    }
}

.u-margin-bottom-medium {
    margin-bottom: 3rem !important;

    @include respond(tab-port) {
        margin-bottom: 2.5rem !important;
    }
}

.u-margin-bottom-small {
    margin-bottom: 1.5rem !important;
}

.u-margin-top-big {
    margin-top: 10rem !important;

    @include respond(tab-port) {
        margin-top: 8rem !important;
    }
}

.u-margin-top-medium {
    margin-top: 6rem !important;

    @include respond(tab-port) {
        margin-top: 4rem !important;
    }
}