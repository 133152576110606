@mixin clearfix {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Media Query Manager - Define breakpoints in one place

  0 - 600px Phone
  600 - 900px Tablet
  900 - 1200px Tablet landscape
  1200 - 1800px Standard desktop size
  1800+ px Big desktop
  */


/* breakpoint values are: phone, tab-port, tab-land, big-desktop
Usage: 
  @include respond(tab-port) {
    -> styles to overwrite
  }

  Make sure to order queries properly from small to big
*/
@mixin respond($breakpoint) {
  @if $breakpoint==phone {
    @media only screen and (max-width: 37.5em) {
      
      //600px
      @content
    };
  }

  @if $breakpoint==tab-port {

    //900px
    @media only screen and (max-width: 56.25em) {
      @content
    };
  }

  @if $breakpoint==tab-port-hover {

    //900px
    @media only screen and (max-width: 56.25em) and (hover:none) {
      @content
    };
  }

  @if $breakpoint==tab-land {

    //1200px
    @media only screen and (max-width: 75em) {
      @content
    };
  }

  @if $breakpoint==tab-land-hover {

    //1200px
    @media only screen and (max-width: 75em),
    only screen and (hover:none) {
      @content
    };
  }

  @if $breakpoint==big-desktop {

    //1800px
    @media only screen and (min-width: 112.5em) {
      @content
    };
  }
}

@mixin image-respond {
  @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
    (-webkit-min-device-pixel-ration: 2) and (min-width: 37.5em),
    (min-width: 125em) {
        @content
    };
}