.header {
    height: 85vh;
    background-image: linear-gradient(to right bottom,
            rgba($color-primary-light, 0.8),
            rgba($color-primary-dark, 0.8)),
            url("../../../public/assets/images/hero-small.jpg");
    background-size: cover;
    background-position: top;
    position: relative;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);

    @supports (-webkit-clip-path: polygon(0 0)) or (clip-path: polygon(0 0)) {
        height: 95vh;
    }

    @include image-respond {
        background-image: linear-gradient(to right bottom,
            rgba($color-primary-light, 0.8),
            rgba($color-primary-dark, 0.8)),
        url("../../../public/assets/images/hero.jpg");
    }

    @include respond(phone) {
        height: 80vh;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 70vh, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 70vh, 0 100%);
    }

    &__logo-box {
        position: absolute;
        top: 4rem;
        left: 4rem;
        
        @include respond(phone) {
            top: .5rem;
            left: 0;
        }
    }

    &__logo {
        height: 9.5rem;
    }

    &__text-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%);
        text-align: center;
    }
}

.header-reduced {
    height: 20vh;
    background-image: linear-gradient(to right bottom,
            rgba($color-primary-light, 0.8),
            rgba($color-primary-dark, 0.8));
    position: relative;

    @include respond(phone) {
        height: 15vh;
    }

    &__logo-box {
        position: absolute;
        top: 4rem;
        left: 4rem;

        @include respond(phone) {
            top: .5rem;
            left: 0;
        }
    }

    &__logo {
        height: 9.5rem;
    }

    &__text-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}