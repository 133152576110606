.section-ribbon {
    padding: 20rem 0;
    background-image: linear-gradient(to right bottom,
            rgba($color-primary-light, 0.8),
            rgba($color-primary-dark, 0.8)),
        url("../../../public/assets/images/ribbon-small.jpg");
    background-size: cover;
    margin-top: -11rem;
    transform: skewY(-7deg);

    @include image-respond {
        background-image: linear-gradient(to right bottom,
            rgba($color-primary-light, 0.8),
            rgba($color-primary-dark, 0.8)),
        url("../../../public/assets/images/ribbon.jpg");
    }


    & > * {
        transform: skewY(7deg);
    }

    @include respond(tab-port) {
        padding: 10rem 0;
    }
}