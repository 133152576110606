.popup {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color-black, .8);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;

    //check for browser support
    @supports(-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px) saturate(1000%);
        backdrop-filter: blur(10px) saturate(1000%);
        background-color: rgba($color-black, .3);
    }

    &:target {
        opacity: 1;
        visibility: visible;
    }

    &__backdrop {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
    }

    &__content {
        @include absCenter;

        width: 75%;
        background-image: linear-gradient(
            120deg,
            rgba($color-white, .45),
            rgba($color-white, .75), 70%, 
            rgba($color-white, .45)
        );
        border-radius: 2rem;
        border: 1px solid rgba($color-white, .25);
        box-shadow: 0 0 1rem .1rem rgba($color-black, .25);
        display: table;
        opacity: 0;
        transform: translate(-50%, -50%) scale(.1);
        transition: all .6s .2s;
        overflow: hidden;
        z-index: 2;

        @include respond(tab-land) {
            display: inline-block;
        }
    }

    &__left {
        width: 33.33333333%;
        display: table-cell;

        @include respond(tab-land) {
            width: 100%;
            display: inline-block;
            padding: 5rem 2rem 2rem 2rem;
        }
    }

    &__right {
        width: 66.666666667;
        display: table-cell;
        vertical-align: top;
        padding: 3rem 5rem;

        @include respond(tab-land) {
            width: 100%;
            display: inline-block;
        }
    }

    &:target &__content {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    &__close {

        &:link,
        &:visited {
            color: $color-gray-dark;
            position: absolute;
            top: 1rem;
            right: 2.5rem;
            font-size: 3rem;
            text-decoration: none;
            display: inline-block;
            line-height: 1;
            transition: all .2s;

            &:hover,
            &:active {
                color: $color-primary;
            }
        }
    }

    &__img {
        display: block;
        width: 100%;

        @include respond(tab-land) {
            height: 25rem;
            width: auto;
            display: inline-block;
        }

        @include respond(tab-port) {
            height: 20rem;
        }
    }

    &__text {
        font-size: 1.4rem;
        margin-bottom: 4rem;
        /*
        -moz-column-count: 2;
        -moz-column-gap: 4rem;
        -moz-column-rule: 1px solid $color-gray-medium;

        column-count: 2;
        column-gap: 4rem;
        column-rule: 1px solid $color-gray-medium;*/

        //hyphens: auto;

        @include respond(tab-port) {
            -moz-column-count: 1;

            column-count: 1;
        }
    }
}