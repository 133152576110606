/* base font styling*/
body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: $default-font-size;
    line-height: 1.7;
    color: $color-gray-dark;
    padding: 3rem;
}

//Text defined for use in top-level headers
.heading-primary {
    color: $color-white;
    text-transform: uppercase;
    margin-bottom: 6rem;

    /*fix animation jitter */
    backface-visibility: hidden;

    &--main {
        display: block;
        font-size: 6rem;
        font-weight: 400;
        letter-spacing: 3.5rem;

        animation-name: moveInLeft;
        animation-duration: 1s;
        animation-timing-function: ease-out;

        @include respond(phone) {
            letter-spacing: 1rem;
            font-size: 5rem;
        }
    }

    &--sub {
        display: block;
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: 1.75rem;

        animation: moveInRight 1s ease-out;

        @include respond(phone) {
            letter-spacing: .5rem;
        }
    }

    &--tagline {
        display: block;
        font-weight: 200;
        letter-spacing: .25rem;
        color: $color-gray-medium;

        animation: moveInBottom .5s ease-out .75s;
        animation-fill-mode: backwards;
    }
}

//Text defined for use in top-level headers in sub pages
.heading-reduced-primary {
    color: $color-white;
    text-transform: uppercase;
    margin-bottom: 1rem;

    /*fix animation jitter */
    backface-visibility: hidden;

    &--main {
        display: block;
        font-size: 4rem;
        font-weight: 400;
        letter-spacing: 1.5rem;

        animation: moveInLeft 1s ease-out;

        @include respond(phone) {
            letter-spacing: 1rem;
            font-size: 3rem;
        }
    }

    &--sub {
        display: block;
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: 1rem;

        animation: moveInRight 1s ease-out;

        @include respond(phone) {
            letter-spacing: .5rem;
        }
    }
}

.heading-secondary {
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    background-image: 
        linear-gradient(
            to right, 
            $color-primary,
            $color-primary-dark
        );
    display: inline-block;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: .2rem;
    transition: all .5s;

    @include respond(tab-port) {
        font-size: 3rem;
    }

    @include respond(phone) {
        font-size: 2.5rem;
    }

    &:hover {
        transform: scale(1.05);
        text-shadow: .5rem 1rem 2rem rgba($color-black, .1);
    }

    &--solid {
        background-image: 
        linear-gradient(
            to right, 
            $color-primary,
            $color-primary-light
        );
    }
}

.heading-tertiary {
    font-size: $default-font-size;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph {
    font-size: $default-font-size;

    &:not(:last-child) {
        margin-bottom: 3rem;
    }
}

.page-quote {
    font-size: $default-font-size;
    display: block;
}