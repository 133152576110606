.section-contact {
    padding: 15rem 0;
    background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);

}

.contact {
    background-image: linear-gradient(
        105deg, 
        rgba($color-white, .9) 0%, 
        rgba($color-secondary, .9) 54.8%,
        transparent 55%
        ), 
        url("../../../public/assets/images/sdw-contact-bak-large.jpg");
    background-size: 100%;
    border-radius: .3rem;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .2);

    @include respond(tab-land) {
        background-image: linear-gradient(
        105deg, 
        rgba($color-white, .9) 0%, 
        rgba($color-secondary, .9) 64.8%,
        transparent 65%
        ), 
        url("../../../public/assets/images/sdw-contact-bak-large.jpg");
        background-size: cover;
    }

    @include respond(tab-port) {
        background-image: linear-gradient(to right, 
        rgba($color-white, .9) 0%, 
        rgba($color-secondary, .9) 100%
        ), 
        url("../../../public/assets/images/sdw-contact-bak-large.jpg");
        background-size: cover;
    }

    &__form {
        width: 50%;
        padding: 6rem;

        @include respond(tab-land) {
            width: 65%;
        }

        @include respond(tab-port) {
            width: 100%;
        }
    }

    &__photo {
        transform: translate(-8rem, -1rem);
        width: 45%;
        box-shadow: 0 1.5rem 2rem rgba($color-black, .4);
        border-radius: 5px;
        transition: all .4s;
    }

    &__icon {
        font-size: 3rem;
        // Gradient on text
        background-image:
            linear-gradient(to bottom,
                $color-tertiary,
                $color-black);
        display: inline-block;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        // end of gradient on text
    }

    &__icon-label {
        display: block;
        color: $color-black;
    }

    &__links {
        position: relative;
        float: right;
    }

    &__list {
        list-style: none;
    }

    &__item {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 1.5rem
        }
    }

    &__link {

        &:link,
        &:visited {
            color: $color-gray-light-1;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            display: inline-block;
            transition: all .2s;
        }

        &:hover,
        &:active {
            color: $color-primary;
            transform: scale(1.3);
        }
    }
}