.composition {
    position: relative;
    margin-top: 4rem;

    @include respond(tab-port) {
        margin-top: 2rem;
    }

    &__photo {
        width: 65%;
        box-shadow: 0 1.5rem 2rem rgba($color-black, .4);
        border-radius: 5px;
        position: absolute;
        z-index: 10;
        transition: all .4s;

        @include respond(tab-port) {
            float: left;
            position: relative;
            width: 33.333333333%;
            box-shadow: 0 1.5rem 3rem rgba($color-black, .2);
        }

        &--p1 {
            left: -2rem;
            top: -2rem;
            z-index: 15;

            @include respond(tab-port) {
                top: 0;
                transform: scale(1.2);
            }
        }

        &--p2 {
            right: 0;
            top: 2rem;
            z-index: 14;
            transform: rotate(2deg);

            @include respond(tab-port) {
                top: -1rem;
                transform: scale(1.3);
            }
        }

        &--p3 {
            left: -2rem;
            top: 10rem;
            transform: rotate(-3deg);

            @include respond(tab-port) {
                top: 1rem;
                left: 0;
                transform: scale(1.1);
            }
        }

        &--p4 {
            right: 0;
            top: -3rem;
            z-index: 15;
            transform: rotate(2deg);

            @include respond(tab-port) {
                top: 0;
                transform: scale(1.2);
            }
        }

        &--p5 {
            left: -2rem;
            top: 2rem;
            z-index: 14;
            transform: rotate(-2deg);

            @include respond(tab-port) {
                z-index: 10;
                top: -1rem;
                transform: scale(1.3);
            }
        }

        &--p6 {
            right: 3rem;
            top: 15rem;
            z-index: 16;

            @include respond(tab-port) {
                top: 1rem;
                left: 0;
                transform: scale(1.1);
            }
        }

        &--p7 {
            left: 20%;
            top: 0;

            @include respond(tab-port) {
                top: 0;
                transform: scale(1.2);
            }
        }

        &:hover {
            transform: scale(1.25) translateY(-.5rem) rotate(0);
            box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
            z-index: 20;
        }
    }

    &:hover &__photo:not(:hover) {
        filter: blur(.1rem) brightness(80%);
    }
}